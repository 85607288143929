import React from 'react';
import { Helmet } from 'react-helmet';
import '../scss/main.scss';
import Header from '../components/sections/header';
import Solution from '../components/sections/solution';
import Decision from '../components/sections/decision';
import Videos from '../components/sections/videos';
import Footer from '../components/sections/footer';

export default () => (
    <div className="vct-main">
        <Helmet>
            <html className="no-js" lang="en" />
            <title>Vector Communications | Intelligent Networks</title>
            <meta name="description" content="Intelligent networks - The smarter way to connect" />
        </Helmet>
        
        <Header></Header>
        <Solution></Solution>
        <Decision></Decision>
        <Videos></Videos>
        <Footer></Footer>
    </div>
)
