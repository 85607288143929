import React from 'react';
import { Button } from 'reactstrap';
import animateScrollTo from 'animated-scroll-to';
import Navbar from '../widgets/navbar';

class Header extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.scrollToOverview.bind(this);
    }
    
    scrollToOverview() {
        const nodeOverview = document.getElementById('overviewVideoContainer');
        animateScrollTo(nodeOverview, { offset: -45 });
    }
    
    render() {
        return (
            <div className="vct-header">
                <Navbar 
                    brandImage={ { src: '/images/vector-comms-logo.png', alt: 'Vector Comms logo', url: 'https://vectorcomms.co.nz/' } }
                    aboutUs={ { url: 'https://vectorcomms.co.nz/about-us', text: 'About us' } }
                    phone={ { src: '/images/vector-phone.png', alt: 'Phone icon', url: 'tel:0800826436', text: '0800 826 436' } }>
                </Navbar>
                
                <div className="jumbotron">
                    <h1>
                        <span>Intelligent networks</span>
                        <span>the smarter way to connect</span>
                    </h1>
                </div>
                
                <div className="vct-header-scroll">
                    <div className="d-flex flex-row justify-content-center">
                        <Button onClick={ this.scrollToOverview } className="vct-btn-icon"><img src="/images/vctr-expand.svg" alt="Arrow down" /></Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
