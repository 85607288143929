import React from 'react';

export default () => (
    <section className="vct-decision">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p>Vector Communications Intelligent Networks will change how you think about business networking. Our 100% owned and managed service means you only pay for capability, not hardware and maintenance.</p>
                    
                    <div className="vct-decision-btn-wrapper">
                        <a href="mailto:contactus@vector.co.nz" className="btn btn-outline-light vct-cta-link-btn">Talk to us today</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
)
