import React from 'react';

export default () => (
    <section className="vct-footer">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    &copy;2018 VectorComms.co.nz. All Rights Reserved. <a href="https://vectorcomms.co.nz/terms-and-conditions">Terms and Conditions</a> | <a href="https://www.vector.co.nz/privacy-policy">Privacy Policy</a>
                </div>
            </div>
        </div>
    </section>
)
