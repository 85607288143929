import React from 'react';
import SolutionItem from '../widgets/solution-item';
import VideoLinkMain from '../widgets/video-link-main';

export default () => (
    <section className="vct-solution">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                    <h2>One solution to network owners&#8217; 5 biggest headaches</h2>
                </div>
            </div>
            
            <div className="row vct-solution-items justify-content-center">
                <div className="col-12 col-xl-4">
                    <SolutionItem image={{ src: '/images/vector-dollar.png', alt: 'Application Performance image' }} heading="Application Performance" description="Application performance means giving priority to the traffic that needs it, when it needs it"></SolutionItem>
                </div>
                <div className="col-12 col-xl-4">
                    <SolutionItem image={{ src: '/images/vector-double-check.png', alt: 'Resiliency image' }} heading="Resiliency" description="Resiliency reinvented means multiple channels keep you connected without the upfront expense"></SolutionItem>
                </div>
                <div className="col-12 col-xl-4">
                    <SolutionItem image={{ src: '/images/vector-lock.png', alt: 'Security image' }} heading="Security" description="Security on site enables internet breakout without the risk of break-ins"></SolutionItem>
                </div>
                <div className="col-12 col-xl-4">
                    <SolutionItem image={{ src: '/images/vector-funnel.png', alt: 'Bottlenecks image' }} heading="Bottlenecks" description="Bottlenecks are busted via direct secure cloud access from all your sites"></SolutionItem>
                </div>
                <div className="col-12 col-xl-4">
                    <SolutionItem image={{ src: '/images/vector-clock.png', alt: 'Lead time image' }} heading="Lead time" description="Lead time is slashed with one-day provision on connectivity then phased service rollout"></SolutionItem>
                </div>
            </div>
            
            <div className="row justify-content-center" id="overviewVideoContainer">
                <div className="col-12">
                    <VideoLinkMain videoUrl="/" image={{ src: '/images/video-thumbnail-main.png', alt: 'Overview video' }} label="Watch overview video" modalTitle="Overview Video" className="modal-dialog-centered vct-modal-dialog"></VideoLinkMain>
                </div>
            </div>
        </div>
    </section>
)
