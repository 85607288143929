import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

class VideoLinkMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    
        this.toggle = this.toggle.bind(this);
    }
    
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    
    render() {
        return (
            <div className="vct-video-link-main d-flex">
                <div className="vct-video-link-label">
                    <span>{this.props.label}</span>
                </div>
                
                <div className="vct-video-link-main-image">
                    <Button onClick={this.toggle}><img src={this.props.image.src} alt={this.props.image.alt} /></Button>
                </div>
                
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
                    <ModalBody>
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe title="Video" width="560" height="315" src="https://www.youtube-nocookie.com/embed/8mGixSEm370?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen ></iframe>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

VideoLinkMain.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }),
    label: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired
};

VideoLinkMain.defaultProps = {
    label: 'Watch overview video'
};

export default VideoLinkMain;