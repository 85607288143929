import React from 'react';
import VideoLinkItem from '../widgets/video-link-item';

export default () => (
    <section className="vct-videos">
        <div className="container-fluid">
            <div className="row flex-column justify-content-start flex-xl-row justify-content-xl-between flex-nowrap flex-lg-wrap">
                <div className="col-12 col-md-6 col-xl-5 vct-video-item">
                    <VideoLinkItem 
                        image={{ src: '/images/video-thumbnail-resiliency.png', alt: 'Resiliency and Application Performance video' }} 
                        label="Resiliency and Application Performance video" videoUrl="https://www.youtube-nocookie.com/embed/iRQ88Xww2S8?rel=0"></VideoLinkItem>
                </div>
                
                <div className="col-12 col-md-6 col-xl-5 offset-xl-2 vct-video-item vct-video-item-alt">
                    <VideoLinkItem 
                        image={{ src: '/images/video-thumbnail-cloud.png', alt: 'Cloud Access and Security video' }} 
                        label="Cloud Access and Security video" videoUrl="https://www.youtube-nocookie.com/embed/9AMJy_Upe7U?rel=0"></VideoLinkItem>
                </div>
                
                <div className="col-12 col-md-6 col-xl-5 vct-video-item">
                    <VideoLinkItem 
                        image={{ src: '/images/video-thumbnail-service.png', alt: 'As a Service video' }} 
                        label="As a Service video" videoUrl="https://www.youtube-nocookie.com/embed/Fy-_wgHuSJI?rel=0"></VideoLinkItem>
                </div>
                
                <div className="col-12 col-md-6 col-xl-5 offset-xl-2 vct-video-item vct-video-item-alt">
                    <VideoLinkItem 
                        image={{ src: '/images/video-thumbnail-speed.png', alt: 'Speed of Provisioning video' }} 
                        label="Speed of Provisioning video" videoUrl="https://www.youtube-nocookie.com/embed/7WxbzTNMCu4?rel=0"></VideoLinkItem>
                </div>
                
                <div className="col-md-6 offset-md-6 d-none d-md-block d-md-none d-lg-block d-lg-none d-xl-none vct-videos-avatar"></div>
                <div className="col-xl-2 offset-xl-5 d-none d-xl-block vct-videos-avatar-animated"></div>
            </div>
        </div>
    </section>
)