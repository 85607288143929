import React from 'react';
import PropTypes from 'prop-types';

export const Navbar = ({ brandImage, aboutUs, phone }) => (
    <div className="container">
        <nav className="navbar navbar-light bg-transparent vct-navbar">
            <a className="navbar-brand" href={ brandImage.url }>
                <img src={ brandImage.src } alt={ brandImage.alt } />
            </a>
            <div className="ml-auto">
                <a href={ aboutUs.url } className="vct-navbar-link">
                    <span>{ aboutUs.text }</span>
                </a>
                <a href={ phone.url } className="vct-navbar-link">
                    <img src={ phone.src } alt={ phone.alt } />
                    <span className="vct-navbar-link-phone">{ phone.text }</span>
                </a>
            </div>
        </nav>
    </div>
);

Navbar.propTypes = {
    brandImage: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    aboutUs: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    phone: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    })
};

export default Navbar;