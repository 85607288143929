import React from 'react';
import PropTypes from 'prop-types';

export const SolutionItem = ({ image, heading, description }) => (
    <div className="vct-solution-item">
        <img src={ image.src } alt={ image.alt } className="vct-solution-item-image"/>
        <h3 className="vct-solution-item-heading">{ heading }</h3>
        <p className="vct-solution-item-description">{ description }</p>
    </div>
);

SolutionItem.propTypes = {
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }),
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default SolutionItem;
